/* eslint-disable no-undef */
//Your web app's hms configuration
var hmsConfig = {
    "apiKey": "HR8eZ8MSnSGLAPwVVAiNkKS0Gq973llTBf1Wjm2u",
    "projectId": "388421841222139397",
    "clientId": "110956263",
    "countryCode": "RU"
};

//Initialize Hms
hms.initializeApp(hmsConfig);

const messaging = hms.messaging();
messaging.usePublicVapidKey(
    "BAGJTWp46tK2MuPtgXzJhPlQi7xKDoZuuX7ohCZIFbu_pJMXdvherI_ENrlQwr1i5v9Pwwq3iig69XsdvRJrUrY");
messaging.onMessage((payload) => {
    console.log('Message received. ', payload);
});

const registration = await navigator.serviceWorker.register("hms-messaging-sw.js", {
    scope: "./hms-cloud-messaging-push-scope"
})
// alert('registration')
messaging.useServiceWorker(registration);

try {
    const currentToken = await messaging.getToken()
    if (currentToken) {
        console.log('Current token: ', currentToken);
        // var paragraph = document.getElementById("root");
        // paragraph.innerText = `${currentToken}`;
    } else {
        console.log('No Instance ID token available. Request permission to generate one.');
        // var paragraph1 = document.getElementById("root");
        // paragraph1.innerText = 'No Instance ID token available. Request permission to generate one.';
    }
} catch (err) {
    console.log('An error occurred while retrieving token. ', err)
    // var paragraph2 = document.getElementById("root");
    // paragraph2.innerText = `An error occurred while retrieving token - ${err}`;
}